import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

var contentful = require('contentful')
var contentfulClient = contentful.createClient({
  space: 'dqqbwruiux6b',
  accessToken: '42rYTEuWrvSyuKabB-P6n1h1XjKAnzaAe6HrQRVZW-g'
})

export default new Vuex.Store({
  state: {
    loading:true,
    data:null,
    markers:null,
    noresult: false,
    filtering:0,
    loadimage:0,
    closeWindow: 0,
    activeProjectId: false,
    mapLoaded: false,
    continents: { data : [
      {
        label:'Asia',
        short: 'asia',
        selected: false,
        display: false
      },
      {
        label:'Africa',
        short: 'africa',
        selected: false,
        display: false
      },
      {
        label:'Europe',
        short: 'europe',
        selected: false,
        display: false
      },
      {
        label:'North America',
        short: 'north-america',
        selected: false,
        display: false
      },
      {
        label:'South America',
        short: 'south-america',
        selected: false,
        display: false
      },
      {
        label:'Australia/Oceania',
        short: 'australia-oceania',
        selected: false,
        display: false
      },
      {
        label:'Antarctica',
        short: 'antarctica',
        selected: false,
        display: false
      }
    ]},
    statuses: { data : [
      {
        label:'Complete',
        short: 'complete',
        valu: true,
        selected: false,
        display: false
      },
      {
        label:'In Progress',
        valu: false,
        short: 'incomplete',
        selected: false,
        display: false
      }
    ]}
  },
  mutations: {
    SET_DATA(state, payload) {

      // Set the whole object
      state.data = payload
      state.markers = []
      
      // Set an array of Markers
      state.data.items.forEach(item => {
        state.markers.push(item.fields.gpsCoordinates)
      });

      // Clean up filter menus
      state.statuses.data.forEach(function (status, i)  {
        let res = state.data.items.find(item => {
          return item.fields.completed == status.valu
        });
        if (res) {
          state.statuses.data[i].display = true;
        }
      });

      state.continents.data.forEach(function (continent, i)  {
        let res = state.data.items.find(item => {
          return item.fields.continent[0] == continent.short
        });
        if (res) {
          state.continents.data[i].display = true;
        }
      });

    },
    SET_LOADING(state, payload) {
      state.loading = payload
    },
    SET_NORESULT(state, payload) {
      state.noresult = payload
    },
    LOAD_IMAGE(state, payload) {
      state.loadimage = payload
    },
    CLOSE_WINDOW(state) {
      state.closeWindow++
    },
    SET_ACTIVE(state, payload) {
      state.activeProjectId = payload
    },
    MAP_LOADED(state) {
      state.mapLoaded = true
    },
    TOGGLE_CONTINENT(state, payload) {
      state.continents.data[payload].selected = !state.continents.data[payload].selected
      state.filtering = state.filtering + 1
      state.loadimage = 0
    },
    TOGGLE_STATUS(state, payload) {
      if(payload == 1) {
        state.statuses.data[1].selected = !state.statuses.data[1].selected
        state.statuses.data[0].selected = false
      } else {
        state.statuses.data[0].selected = !state.statuses.data[0].selected
        state.statuses.data[1].selected = false
      }

      state.filtering = state.filtering + 1
      state.loadimage = 0
    }
  },
  actions: {
    setData({commit}) {
      contentfulClient.getEntries()
      .then((res) => {
        commit('SET_DATA', res)
        commit('SET_LOADING', false)
        console.log(res)
      })
      .catch((error) => {
        console.log(error)
      })
    },
    closeWindows({commit}) {
      commit('CLOSE_WINDOW')
    },
    mapLoaded({commit}) {
      commit('MAP_LOADED')
    },
    loadImage({commit}, val) {
      commit('LOAD_IMAGE', val)
    },
    noResult({commit}, val) {
      commit('NO_RESULT', val)
    },
    toggleContinent({commit}, val) {
      commit('TOGGLE_CONTINENT', val)
    },
    toggleStatus({commit}, val) {
      commit('TOGGLE_STATUS', val)
    },
    setActive({commit}, val) {

      if (val === false) {
        document.body.classList.remove("projectOpen");
      } else {
        document.body.classList.add("projectOpen");
      }

      commit('SET_ACTIVE', val)
    }
  },
  getters: {
    getData(state) {

      let carry = []

      state.continents.data.forEach(cont => {
        if(cont.selected == true) {
          carry.push(cont.short)
        }
      })

      state.statuses.data.forEach(stat => {
        if(stat.selected == true) {
          carry.push(stat.short)
        }
      })

      let fdata = state.data.items.filter(item =>  {
        return carry.indexOf(item.fields.continent[0]) !== -1
      });

      if (fdata.length == 0) {
        fdata = state.data.items
      } 

      if(carry.indexOf('complete') !== -1) {
        fdata = fdata.filter(item =>  {
          return item.fields.completed == true
        });
      } else if (carry.indexOf('incomplete') !== -1) {
        fdata = fdata.filter(item =>  {
          return item.fields.completed == false
        });
      }

      return fdata
    },
    getLoading(state) {
      return state.loading
    },
    getFiltering(state) {
      return state.filtering
    },
    getMarkers(state) {
      return state.markers
    },
    getLoadImage(state) {
      return state.loadimage
    },
    getMapLoaded(state) {
      return state.mapLoaded
    },
    getCloseWindow(state) {
      return state.closeWindow
    },
    getActiveProjectId(state) {
      return state.activeProjectId
    },
    getContinents(state) {
      return state.continents.data
    },
    getStatuses(state) {
      return state.statuses.data
    },
    getActiveProject(state) {
      if (state.activeProjectId) {
        // return state.data.items[state.activeProjectId]
        return state.data.items.find( item =>
          item.fields.urlSlug == state.activeProjectId
        )

      } else {
        return false
      }
    },
  }
})
