<template>
  <div class="main-map">
      <div id="mainmap"></div>
      <div v-if="!this.isLoading">
      <div v-if="getData.length == 0" class="no-result d-flex justify-content-center align-items-center text-center">
          <div>
          <h2 style="font-size: calc(1.5vw + 30px)"><strong>No results</strong></h2>
          <p class="lead">Please expand your filters.</p>
          </div>
      </div>
      </div>
  </div>
</template>

<script>

import Vue from 'vue'
import InfoWindowComponent from '@/components/InfoWindow.vue';

export default {
  name: 'MainMap',
  props: {
    msg: String
  },
  data() {
    return {
      mainmap : null,
      markers : { data: [] },
      openWindow : null,
      mapstyles : {
        data: [
            {
                "featureType": "all",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "weight": "2.00"
                    }
                ]
            },
            {
                "featureType": "all",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#9c9c9c"
                    }
                ]
            },
            {
                "featureType": "all",
                "elementType": "labels.text",
                "stylers": [
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "all",
                "stylers": [
                    {
                        "color": "#f2f2f2"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "landscape.man_made",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "all",
                "stylers": [
                    {
                        "saturation": -100
                    },
                    {
                        "lightness": 45
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#eeeeee"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#7b7b7b"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "all",
                "stylers": [
                    {
                        "color": "#46bcec"
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#c8d7d4"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#070707"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "geometry",
                "stylers": [
                { "color": "#bbbbbb" }
                ]
            }
        ]}
    }
  },
   mounted() {
    if(!this.isLoading) {
      window.checkAndAttachMapScript(this.initMainMap);
    }
   },
   methods: {
      redrawMap() {
        this.markers.data.forEach(marker => {
            marker.setMap(null);
        })
        this.markers.data = []
        this.setMapData()
      },
      triggerMapInit() {
        window.checkAndAttachMapScript(this.initMainMap);
      },
      initMainMap() {
        this.mainmap = new window.google.maps.Map(document.getElementById("mainmap"), {
          center: { lat: 0.2934405, lng: 36.2551902 },
          zoom: 8,
          disableDefaultUI: true,
          styles: this.mapstyles.data,
          minZoom: 3,
          maxZoom: 7
        });

        let self = this
        window.google.maps.event.addListenerOnce(this.mainmap, 'idle', function(){
            self.$store.dispatch('mapLoaded')
        });

        this.setMapData()
      },
      setMapData() {

        // Set an array of Markers

        this.getData.forEach((item, index) => {

          let template = '<h1 class="h2">'+ item.fields.title +'</h1>'
          template += '<h2 class="h3">' + item.fields.placeName + '</h2>'
          template += '<p>' + item.fields.popUpText + '</p>'

          item.fields.gpsCoordinates.forEach(str => {
            str = str.replace(/\s/g, '').split(',');
            
            let gps = {
              lat: parseFloat(str[0]),
              lng: parseFloat(str[1])
            }

            let slugtext = item.fields.urlSlug
            let pURL = item.fields.projectUrl
            let pStatus = item.fields.completed

            this.addMarker(gps, template, index, slugtext, pURL, pStatus)
          })

        });

        this.zoomToBounds()
      
      },
      addMarker(gps, contentString, index, slugtext, pURL, pStatus) {

        let InfoWindow = Vue.extend(InfoWindowComponent);
        let instance = new InfoWindow({
            propsData: {
                n: index,
                slug: slugtext,
                content: contentString,
                projecturl: '' + pURL,
                status: pStatus
            }
        });

        instance.$mount();

        let infowindow = new window.google.maps.InfoWindow({
          content: instance.$el,
          maxWidth: 500,
        });

        let micon = "https://images.ctfassets.net/dqqbwruiux6b/78rW7m9a2nbBFs0AOxnopA/65584418349b922cb983da56d72596f9/wild-marker--prog.png"
        if (this.getData[index].fields.completed) {
            micon = "https://images.ctfassets.net/dqqbwruiux6b/6Qmaj8DgDOufl2Anjm3HcH/d81d400d06f9dcf029354dab34d062a3/wild-marker--comp.png"
        }

        let marker = new window.google.maps.Marker({
            n: index,
            slug : slugtext,
            position: gps,
            title: this.getData[index].fields.title,
            icon: {
                url: micon,
                size: new window.google.maps.Size(32, 32),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(16, 16),
                scaledSize: new window.google.maps.Size(32, 32)
            }
        });

        this.markers.data.push(marker)

        let item = this.markers.data.length - 1

        this.markers.data[item].addListener("click", () => {
          if(this.openWindow) {
            this.openWindow.close()
          }
          infowindow.open(this.mainmap, this.markers.data[item]);

          this.$store.dispatch('loadImage', index)

          this.openWindow = infowindow
        });

        // To add the marker to the map, call setMap();
        this.markers.data[item].setMap(this.mainmap);


        // Passive Event Scroller fix?
        (function () {
            if (typeof EventTarget !== "undefined") {
                let func = EventTarget.prototype.addEventListener;
                EventTarget.prototype.addEventListener = function (type, fn, capture) {
                    this.func = func;
                    if(typeof capture !== "boolean"){
                        capture = capture || {};
                        capture.passive = false;
                    }
                    this.func(type, fn, capture);
                };
            }
        }());
      },
      zoomToBounds() {
        let bounds = new window.google.maps.LatLngBounds();
        for (var i = 0; i < this.markers.data.length; i++) {
            bounds.extend(this.markers.data[i].position);
        }
        
        this.mainmap.fitBounds(bounds, 50);
      }
   },
   watch: {
    isLoading() {
        let self = this
        setTimeout(() => {
            self.triggerMapInit()
        }, 2000);
    },
    ifFiltering() {
        this.redrawMap()
    },
    closeTrigger() {
      this.openWindow.close()
    }
   },
   computed: {
     getData() {
       return this.$store.getters.getData
     },
     isLoading() {
      return this.$store.getters.getLoading
     },
     ifFiltering() {
      return this.$store.getters.getFiltering
     },
     closeTrigger() {
      return this.$store.getters.getCloseWindow
     }
   }
}



</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#mainmap, .main-map {
  height: 100%;
}
.no-result {
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    background-color: rgba(255,255,255,0.9);
}
</style>
