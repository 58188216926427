<template>
  <div>
    <MiniMap/>
  </div>
</template>

<script>
import MiniMap from '@/components/MiniMap.vue'

export default {
  name: 'ProjectMap',
  components: {
    MiniMap
  },
  data() {
    return {
    }
  },
  created() {
  },
  mounted() {
    if(!this.isLoading) {
      this.initProject()
    }
  },
  methods: {
    initProject() {
      this.$store.dispatch('setActive', this.$route.params.id)
    }
  },
  watch: {
    isLoading() {
      this.initProject()
    }
  },
  computed: {
     isLoading() {
      return this.$store.getters.getLoading
     },
     activeId() {
      return this.$store.getters.getActiveProjectId
     },
  }
}
</script>


<style lang='scss' rel='stylesheet/scss'>
</style>