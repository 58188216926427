<template>
    <div>
        <div v-html="content" class="popup__content"></div>
        <div class="popup__expandwrap">
            <div class="controls--incomplete" v-if="!status">
                <a href="#" @mouseover.prevent="showImage()" @mouseleave.prevent="hideImage()" class="popup__view d-none d-sm-inline"><i class="ri-eye-line"></i></a>
                <a href="#explore" @click.prevent="setActive()" class="popup__expand">Explore</a>
            </div>
            <div class="controls--complete" v-if="status">
                <a v-bind:href="projecturl" class="popup__expand">View Project <i class="ri-external-link-fill ri-1x" style="vertical-align: text-top;"></i></a>
            </div>
        </div>
        <div class="popup__closebutton" @click="closeWindow()">
            <i class="ri-close-line ri-xl"></i>
        </div>
    </div>
</template>

<script>
import store from '../store/index.js'
import router from '../router/index.js'

export default {
    name: 'infowindow',
    props: [ 
        'n',
        'slug',
        'content',
        'projecturl',
        'status'
    ],
    methods: {
        closeWindow() {
            store.dispatch('closeWindows')
        },
        setActive() {
            router.push('/' + this.slug + '/map')
        },
        visitProject() {

        },
        showImage() {
            document.body.classList.add("windowOpen");
        },
        hideImage() {
            document.body.classList.remove("windowOpen");
        }
    }
}
</script>

<style lang='scss' rel='stylesheet/scss'>
.gm-ui-hover-effect {
    display: none!important;
}
.gm-style-iw-d {

    &>div>div>div:not(:last-child) {
        display:none;
    }


    table>tbody>tr {
        display:none;
        background-color: #fff!important;

        &:last-child{
            display:table-row;
            &>td {
                display:none;
                &:last-child {
                    display:table-cell;
                    font-size:1.3em;
                    font-weight:bold;
                }
            }
        }
    }
}

body.projectOpen .gm-ui-hover-effect {
    display: block!important;
}
</style>
