<template>
  <div class="full-window-fixed" v-if="mapLoaded && getData.length > 0">
    <div v-if="!isLoading" style="width:100%; height:100%">
      <div class="cover-blur" v-show="getImage == index" v-for="(item, index) in getData.items" :key="item.fields.title" :style="{ backgroundImage: 'url(https:' + item.fields.imageGallery[0].fields.file.url + '?w=25&h=25)' }">
        <img :src="'https:' + item.fields.imageGallery[0].fields.file.url + '?w=25&h=25'" alt="" style="opacity:0">
      </div>

      <div class="cover-image" :class="{'show': fadeMe}" :style="{ backgroundImage: 'url(https:' + getImageURL() + '?w=1200&h=1200)' }">
        <img :src="['https:' + getImageURL() + '?w=1200&h=1200']" alt="" style="opacity:0" @load="fadeInImage()">
      </div>
      {{ getImage }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'BackImages',
  props: {
  },
  data() {
    return {
      fadeMe: false
    }
  },
   mounted() {
    // if(!this.isLoading) {
    // }
   },
   methods: {
      getImageURL() {
        return this.getData[this.getImage].fields.imageGallery[0].fields.file.url
      },
      fadeInImage() {
        let self = this
        setTimeout(() => {
          self.fadeMe = true
        }, 100);
      }
   },
   watch: {
    isLoading() {
      
    }
   },
   computed: {
     getData() {
       return this.$store.getters.getData
     },
     isLoading() {
       return this.$store.getters.getLoading
     },
     getImage() {
       return this.$store.getters.getLoadImage
     },
     mapLoaded() {
       return this.$store.getters.getMapLoaded
     },
     ifFiltering() {
      return this.$store.getters.getFiltering
     },
   }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang='scss' rel='stylesheet/scss'>
  .full-window-fixed {
    position:fixed;
    top:0;
    left:0;
    bottom:0;
    right:0;
    background-color:#333;
  }
  .cover-blur {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    filter: blur(50px);
    -webkit-filter: blur(50px);
  }
  .cover-image {
    position:absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    opacity: 0;
    transition: opacity 0.4s;

    &.show {
      opacity:1;
    }
  }
</style>
