<template>
  <div class="mini-map" style="position:relative">
    <div id="minimap" :class="{'displayMap':showMap == true}"></div>

    <ul class="map-key" :class="{'map-key--open':keyopen}" v-if="mapKey.length">
        <li class="map-key__button" @click.prevent="keyopen = !keyopen">
            <i class="ri-close-line ri-xl" :class="{'d-none':!keyopen}"></i>
            <i class="ri-key-2-fill ri-xl" :class="{'d-none':keyopen}"></i>
        </li>
        <li v-for="(keyitem, index) in mapKey" :key="index" class="map-key__li">
            <div class="map-key__label">
                {{ keyitem.label }}
            </div>
            <div v-if=" keyitem.color" class="map-key__color" :style="{ backgroundColor: keyitem.color }">
                {{ keyitem.color }}
            </div>
            <div v-if="keyitem.icon" class="map-key__icon" :class="['map-key__icon' + keyitem.icon]">
                {{ keyitem.icon }}
            </div>
        </li>
    </ul>

    <div class="map-key map-key--info">
        <router-link :to="{path: '/' + $route.params.id + '/'}" class="map-key__button" >
            <i class="ri-information-line ri-xl"></i>
        </router-link>
    </div>

    <div class="map-key map-key--layers">
        <a href="#layers" class="map-key__button" :class="{'off':!showLayers}" @click.prevent="hideLayers()">
            <i class="ri-stack-line ri-lg"></i>
        </a>
    </div>

    <div v-if="activeProject" class="map-credits">
        <b>Layer Data:</b> {{ activeProject.fields.mapCredits }}
    </div>

  </div>
</template>

<script>

export default {
  name: 'MiniMap',
  props: {
    msg: String
  },
  data() {
    return {
      mainmap : null,
      layers : { data: [] },
      markers : { data: [] },
      openWindow : null,
      showLayers: true,
      mapstyles : {
        data: [
            {
                "featureType": "all",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "weight": "2.00"
                    }
                ]
            },
            {
                "featureType": "all",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#9c9c9c"
                    }
                ]
            },
            {
                "featureType": "all",
                "elementType": "labels.text",
                "stylers": [
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "all",
                "stylers": [
                    {
                        "color": "#f2f2f2"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "landscape.man_made",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "all",
                "stylers": [
                    {
                        "saturation": -100
                    },
                    {
                        "lightness": 45
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#eeeeee"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#7b7b7b"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "all",
                "stylers": [
                    {
                        "color": "#46bcec"
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#c8d7d4"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#070707"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            }
        ]},
      mapKey : [],
      keyopen: false,
      showMap: false,
      mapCenter: {
          lat: null,
          lng: null
      },
      detailCenter: {
          lat: null,
          lng: null
      }
    }
  },
   mounted() {
     if(!this.isLoading) {
       setTimeout(() => {
         window.checkAndAttachMapScript(this.initMainMap);
       }, 1000);
     }
   },
   methods: {
      triggerMapInit() {
        window.checkAndAttachMapScript(this.initMainMap);
      },
      initMainMap() {
        let centerPoint = this.activeProject.fields.gpsCoordinates[0].replace(/\s/g, '').split(',');

        this.mapCenter.lat = parseFloat(centerPoint[0])
        this.mapCenter.lng = parseFloat(centerPoint[1])

        this.mainmap = new window.google.maps.Map(document.getElementById("minimap"), {
          center: self.mapCenter,
          zoom: 8,
          
          zoomControl: true,
          mapTypeControl: false,
          scaleControl: true,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,

          mapTypeId: 'hybrid'
        });

        this.setMapData()
      },
      hideLayers() {
          if(this.showLayers) {
              this.showLayers = false
              this.layers.data.forEach((item, index) => {
                  this.layers.data[index].setMap(null)
              })
          } else {
              this.showLayers = true
              this.layers.data.forEach((item, index) => {
                  this.layers.data[index].setMap(this.mainmap)
              })
          }
      },
      setExtents() {
          let self = this

          let centerPoint = this.activeProject.fields.mapDetailCenter.replace(/\s/g, '').split(',');
            
            this.detailCenter.lat = parseFloat(centerPoint[0])
            this.detailCenter.lng = parseFloat(centerPoint[1])

          setTimeout(() => {
            this.mainmap.setZoom(self.activeProject.fields.detailZoomLevel);
            this.mainmap.panTo(self.detailCenter);
            this.showMap = true;
          }, 100);
      },
      setMapData() {

        let self = this

        // Set an array of Layers
        if(Object.prototype.hasOwnProperty.call(this.activeProject.fields, 'projectMapFiles')) {
          
          this.activeProject.fields.projectMapFiles.forEach((item, index) => {
            this.layers.data[index] = new window.google.maps.KmlLayer({
              url: 'https:'+item.fields.file.url,
            });
              this.layers.data[index].setMap(this.mainmap)

              if (this.activeProject.fields.projectMapFiles.length - 1 == index ) {
                  window.google.maps.event.addListener(this.layers.data[index], 'metadata_changed', function () {
                      self.setExtents()
                  })
              }
          });

        }

        // Add Marker
        let map = this.mainmap
        let image = 'https://images.ctfassets.net/dqqbwruiux6b/6Yi1xFWng1azr1dN3eoeII/02ee7b7cdefd1660ba4d2e0077123c8b/wild-marker--point2.png'
        new window.google.maps.Marker({
            position: self.mapCenter,
            map,
            title: self.activeProject.fields.title,
            icon: image,
        });


        if(Object.prototype.hasOwnProperty.call(this.activeProject.fields, 'mapKey')) {
            this.mapKey = this.activeProject.fields.mapKey
        }
      
      }
   },
   watch: {
    isLoading() {
      this.triggerMapInit()
    },
    closeTrigger() {
      this.openWindow.close()
    }
   },
   computed: {
    activeProject() {
      return this.$store.getters.getActiveProject
    },
    isLoading() {
    return this.$store.getters.getLoading
    },
    closeTrigger() {
    return this.$store.getters.getCloseWindow
    }
   }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#minimap, .mini-map {
  margin-top: 35px;
  height: calc(100vh - 105px);
}
.map-credits {
    position: absolute;
    bottom:35px;
    left:0;
    margin-right:5px;
    max-width:200px;
    padding:10px 10px 10px 15px;
    border-radius: 0 5px 5px 0;
    font-size: 10px;
    line-height:13px;
    color:#ccc;
    background-color: rgba(0,0,0,0.7);
    opacity:0.4;
}
.map-credits:hover {
    opacity:1;
}
.map-credits b {
    color:#eee;
}
</style>
