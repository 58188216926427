<template>
  <div id="app">
    <router-view />
    <div class="loading" :class="{loaded:!isLoading}"></div>
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
    }
  },
  created() {
    this.$store.dispatch('setData')
  },
  mounted() {

  },
  computed: {
    isLoading() {
      return this.$store.getters.getLoading
    }
  }
}
</script>

<style lang='scss' rel='stylesheet/scss'>
  html,
  body {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  #app {
    min-height:100%;
    width:100%;
  }
  .loading {
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color: #fff;
    opacity:1;
    z-index: 10000;

    transition: opacity 0.8s ease-out 1s;

    &.loaded {
      opacity:0;
      pointer-events: none;
    }

  }
  @import 'assets/sass/main.scss';
</style>
