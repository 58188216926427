<template>
<div class="min100">
  <header>
    <div class="site-logo">
    </div>
    <div v-if="activeProjectId" class="project-header">
      <h1 class="h2 py-2" style="margin:0">{{ activeProject.fields.title }}</h1>
    </div>
    <div class="site-navigation">
        <div class="filter" :class="{'show-dropdown':showDropdown}" v-if="!activeProjectId" @click.prevent="showDropdown = !showDropdown">
          <div class="filter__top d-flex justify-content-between align-items-center">
             <span>Filter<span class="d-none d-md-inline"> Projects</span></span> <span><i class="ri-filter-2-fill"></i> <i class="ri-arrow-down-s-line"></i></span>
          </div>
          <div class="filter__dropdown">
            <ul class="list-unstyled m-0 p-0" @click.prevent.stop="showDropdown = showDropdown">
              <li class="filter__heading">Project Status</li>
              <li class="filter__option" v-for="(status, index) in statuses" :key="status.short" @click="toggleStatus(index)" :class="{ 'active-me':status.selected, 'd-none':!status.display }"><span>{{ status.label }}</span> <span><i class="ri-checkbox-blank-circle-fill"></i><i class="ri-checkbox-circle-fill"></i></span></li>
              <li class="filter__heading">Continent</li>
              <li class="filter__option" v-for="(continent, index) in continents" :key="continent.short" @click="toggleContinent(index)" :class="{ 'active-me':continent.selected, 'd-none':!continent.display }"><span>{{ continent.label }}</span> <span><i class="ri-checkbox-blank-circle-fill"></i><i class="ri-checkbox-circle-fill"></i></span></li>
            </ul>
          </div>
        </div>
      <div v-if="activeProjectId"><i class="ri-close-line ri-xl" @click="closeActiveProject"></i></div>
    </div>
  </header>
  
  <nav class="project-nav">
    <ul>
      <li class="iconme d-none d-md-inline">
        <a href="#" class="" aria-current="page" @click="closeActiveProject"><i class="ri-arrow-left-s-line ri-2x"></i></a>
      </li>
      <li><router-link :to="{path: '/' + $route.params.id + '/map'}"><span class="d-none d-md-inline">Location </span>Map</router-link></li>
      <li><router-link :to="{path: '/' + $route.params.id + '/gallery'}"><span class="d-none d-md-inline">Image </span>Gallery</router-link></li>
      <li><router-link :to="{path: '/' + $route.params.id + '/'}"><span class="d-none d-md-inline">Project </span>Info</router-link></li>
    </ul>
  </nav>

  <div class="home">
    <backImages />
    <MainMap />
    <div class="project-container" v-show="activeProject">
      <router-view></router-view>
    </div>
  </div>
</div>
</template>

<script>

// @ is an alias to /src
import MainMap from '@/components/MainMap.vue'
import BackImages from '@/components/BackImages.vue'

export default {
  name: 'Home',
  components: {
    MainMap,
    BackImages
  },
  data() {
    return {
      client: null,
      showDropdown: false,
      projectStatus: null
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    toggleContinent(i) {
      this.$store.dispatch('toggleContinent', i)
    },
    toggleStatus(i) {
      this.$store.dispatch('toggleStatus', i)
    },
    closeActiveProject() {
      this.$store.dispatch('setActive', false)
      this.$router.push('/')
    }
  },
  computed: {
    activeProject() {
      return this.$store.getters.getActiveProject
    },
    activeProjectId() {
      return this.$store.getters.getActiveProjectId
    },
    continents() {
      return this.$store.getters.getContinents
    },
    statuses() {
      return this.$store.getters.getStatuses
    },
  }
}
</script>


<style lang='scss' rel='stylesheet/scss'>
.min100 {
    position:relative;
    min-height: 100vh;
}
.home {
  display:block;
  position: relative;
  height: 100vh;
  padding-top:70px;
  width:100%;
  z-index: 1;


  .project-container {
    position: absolute;
    background-color:#fff;
    top:70px;
    left:0;
    right:0;
    min-height:calc(100vh - 70px);
  }
}
header {
  height:70px;
  position: absolute;
  top:0;
  left:0;
  right:0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 5px 5px rgba(0,0,0,0.1);
  z-index: 99;
}
.site-navigation {
  .ri-close-line {
    font-size:2.6em;
    vertical-align: middle;
    opacity:0.5;

    transition: opacity 0.3s;

    &:hover {
      opacity:0.9;
      cursor:pointer;
    }
  }
}
.project-header {
  text-align:center;
  padding:0 15px;
}


</style>