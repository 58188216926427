<template>
  <div>
    <div class="container-fluid">
      <div class="py-5"></div>
      <div v-if="activeProject">
        <div class="row mb-4 mb-md-5">
          <div class="col-12 col-md-1 col-xl-2">

          </div>
          <div class="col-12 col-md-10 col-xl-9 px-4 px-md-0 pb-3 pb-md-0">

            <div class="row">
              <div class="col-6 col-md-4 mb-3" v-for="(image, index) in activeProject.fields.imageGallery" :key="index">

                <a :href="'https:' + image.fields.file.url + '?w=1000'" data-gall="gallery" class="info-thumb venobox" :style="{ 'background-image': 'url(https:' + image.fields.file.url + '?w=400)' }" :title="image.fields.title" style="display:block"></a>
              </div>
            </div>
          </div>
        </div>

        <div class="py-5"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectGallery',
  data() {
    return {
    }
  },
  created() {
  },
  mounted() {
    if(!this.isLoading) {
      this.initProject()
      window.$('.venobox').venobox();
    }
  },
  methods: {
    initProject() {
      this.$store.dispatch('setActive', this.$route.params.id)
    }
  },
  watch: {
    isLoading() {
      this.initProject()
    }
  },
  computed: {
    isLoading() {
      return this.$store.getters.getLoading
    },
    activeProject() {
      return this.$store.getters.getActiveProject
    },
  }
}
</script>

<style lang='scss' rel='stylesheet/scss'>
</style>