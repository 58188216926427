<template>
  <div>
    <div class="container-fluid">
      <div class="py-5"></div>
      <div v-if="activeProject">
        <div class="row mb-4 mb-md-5 align-items-end">
          
          <div class="col-12 col-md-10 col-xl-9 pb-3 pb-md-0">
            <div class="ms-2 ms-md-0 me-2 me-md-0">
              <div class="info-image" :style="{ 'background-image': 'url(https:' + activeProject.fields.mainImage.fields.file.url + '?w=800)' }">

              </div>
            </div>
          </div>
          <div class="col-12 col-md-2 order-md-first">
            <div class="ms-2 ms-md-0 ms-xl-5 me-2 me-lg-0">
              <div class="d-flex">
                <i class="ri-camera-line ri-lg me-1" style="opacity:0.4"></i>
                <p class="mb-0 small info-caption ">
                    {{ activeProject.fields.mainImage.fields.description }}
                </p> 
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-2">
            <div class="ms-2 ms-md-5 me-2 me-md-0">
            </div>
          </div>
          <div class="col-12 col-md-7 offset-md-1">
            <div class="ms-2 ms-md-0 me-2 me-md-0">
              <vue-markdown :source="activeProject.fields.fullText"></vue-markdown>



              <div class="py-3"></div>
              <div class="row">
                <div class="col-12 text-start">
                   <a :href="activeProject.fields.projectUrl" class="info-button" target="projectsWindow">Open Project</a> 
                </div>
              </div>
              <div class="py-5"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import VueMarkdown from 'vue-markdown-render'

export default {
  name: 'ProjectInfo',
  components: {
    VueMarkdown
  },
  data() {
    return {
    }
  },
  created() {
  },
  mounted() {
    if(!this.isLoading) {
      this.initProject()
    }
  },
  methods: {
    initProject() {
      this.$store.dispatch('setActive', this.$route.params.id)
    }
  },
  watch: {
    isLoading() {
      this.initProject()
    }
  },
  computed: {
    isLoading() {
      return this.$store.getters.getLoading
    },
    activeProject() {
      return this.$store.getters.getActiveProject
    },
  }
}
</script>

<style lang='scss' rel='stylesheet/scss'>
</style>