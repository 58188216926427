import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import ProjectMap from '../views/ProjectMap.vue'
import ProjectGallery from '../views/ProjectGallery.vue'
import ProjectInfo from '../views/ProjectInfo.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: [
      {
        name:'info',
        path: '/:id',
        component: ProjectInfo
      },
      {
        name:'map',
        path: '/:id/map',
        component: ProjectMap
      },
      {
        name:'gallery',
        path: '/:id/gallery',
        component: ProjectGallery
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
